import HTTP from '@/utils/http'
/**
 * 
 * @param {*} params 
 */


//  我的民意列表
export const getEventLists = (params) => HTTP("/api/v2/event/myEventList",params,true,)

export const getName = (params) => HTTP("/api/v2/area/areaList",params,true,)

// 实名认证
export const postUser = (params) => HTTP("/api/user/bindMoblie",params,false,)

// 发送验证码
export const getCode = (params) => HTTP("/api/sms/send", params,false,)

// 我的意见征集列表
export const getMycollectList = (params) => HTTP("/api/v2/collect/mycollectList", params,false,)
// 意见表详情
export const getCollectDetail= (params) => HTTP("/api/v2/collect/index",params,true,)
// 获取民意直通车详情
export const getSuggestDetaill = (params) => HTTP("/api/v2/event/index",params,false,)

//投票列表
export const getVote= (params) => HTTP("/api/v2/vote/voteList",params,false,)
// 投票表单列表
export const getVoteDetail= (params) => HTTP("/api/v2/vote/index",params,false,)

// 我的问卷调查列表
export const getQuestionList= (params) => HTTP("/api/v2/questionnaire/mycollectList",params,false,)
export const getQuestionDetail= (params) => HTTP("/api/v2/questionnaire/index",params,false,)
export const subQuestion= (params) => HTTP("/api/v2/questionnaire/post",params,false,'post')



//发布民意
export const eventPost= (params) => HTTP("/api/v2/event/post",params,true,)